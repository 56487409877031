<template>
  <el-container style="height:calc(100vh);">

    <el-main style="width:75%; height:calc(100vh);padding:0 !important;">
      <el-header>
        <el-row>
          <el-col :span="14" class="btn">
            <!-- <div :class="tabsChange==='1'?'btn1 left':'left'" @click="tabsChange='1'">服务列表</div> -->
            <div :class="tabsChange==='2'?'btn1 right':'right'" @click="tabsChange='2'">商品列表</div>
          </el-col>
          <el-col :span="10" class="search">
            <!-- <el-input class="searchInput" placeholder="请输入服务或商品名称" prefix-icon="el-icon-search" v-model="searchVal"
              @keyup.enter.native="searchChange">
            </el-input> -->
          </el-col>
        </el-row>
      </el-header>
      <el-container style="height:calc(91vh);">
        <el-aside class="aside" width="150px">
          <el-menu class="menu">
            <el-menu-item v-for=" (item,index) in goodList" :key="index">
              <template slot="title">
                <div :class="activeItem===index?'nav1':'nav'" @click="activeItem=index">
                  <span>{{item.name}}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main class="left-main">
            <el-row :gutter="15" v-if="goodList[activeItem]">
              <el-col class="cursor" :span="rowCol" v-for="(item ,i) in goodList[activeItem].shop" :key="i">
                <div :class="activeItemList.indexOf(item)!==-1?'order-card order-card1':'order-card'"
                  @click="activeList(item)">
                  <div class="card-name">{{ item.name}}</div>
                  <div class="btm">
                    <div class="btm-time">{{ item.shopspecs_id }}</div>
                    <div class="btm-price">
                      <del>¥{{ item.original_price }}</del>
                      <strong>¥{{ item.vip_price }}</strong>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-main>
        </el-container>
      </el-container>
    </el-main>
    <el-main style="width:25%;height:calc(100vh);min-width:260px;padding:0 !important;">
      <el-container style="height:calc(100vh);border-left: 1px solid #e6e6e6; background-color:#fff;">
        <el-header class="order-header">
          <div class="order-box">
            <div class="order-title" style="height: 20px;">
              <!-- <div class="left-btn">
                <span class="cursor" @click="AddVip">新增会员</span><span class="cursor"
                  @click="closeOrder">关闭本单</span>
              </div>
              <div class="right cursor" @click="getSingleShow=true">
                取单
                <span>6</span>
              </div> -->
            </div>
            <div class="order-vip cursor" @click="orderChangeTeacherShow=true">
              <div>
                <img :src="$getimgsrc(orderTeacherName.avatar)" alt="" v-if="orderTeacherName.avatar" style="border-radius: 50%;">
                <img src="@/assets/images/avatar.png" alt="" v-else>
                <span class="name">{{ orderTeacherName.realname || '点击选择会员'}}</span>
              </div>
              <b class="iconfont icon-jia"></b>
            </div>
          </div>
        </el-header>
        <el-main class="order-main">
          <div class="order" ref="orderWidth">
            <div class="order-body">
              <div class="goods-card cursor" v-for="(item ,i) in activeItemList" :key="i">
                <template>

                  <!-- <div v-if="i" class="card-t">
                    <div class="t-name">{{  item.name}}</div>
                    <div>
                      <span class="t-change iconfont icon-bianji" @click="serviceDialogShow=true"></span>
                      <span class="t-del iconfont icon-cha" @click="delOrderGoods"></span>
                    </div>
                  </div> -->
                  <div  class="card-t">
                    <div class="t-name">{{  item.name}}</div>
                    <!-- <div>
                      <span class="t-change iconfont icon-bianji" @click="goodsDialogShow=true"></span>
                      <span class="t-del iconfont icon-cha" @click="delOrderGoods"></span>
                    </div> -->
                  </div>
                </template>
                <div class="card-c">
                  <template>
                    <!-- <div v-if="i" class="c-time">30分钟</div> -->
                    <div  class="c-time">{{  item.shopspecs_id}}</div>
                  </template>
                  <div class="c-price">¥{{ item.original_price }} <span>¥{{ item.vip_price }}</span></div>
                </div>
                <template>
                  <!-- <div v-if="i" class="card-b" @click="orderChangeTeacherShow=true">
                    <div class="b-name">
                      <img src="@/assets/images/avatar.png" alt="">
                      <div class="b-n-name">{{orderTeacherName}}</div>
                    </div>
                    <div class="b-change">选择 <i class="iconfont icon-arrow-right-bold"></i></div>
                  </div> -->
                  <div  class="card-b">
                    <div class="b-name">
                      <div class="stepper">
                        <el-input-number v-model="item.addnum" :precision="0" :min="1"  @change="numchang">
                        </el-input-number>
                      </div>
                    </div>
                    <!-- <div class="b-change">
                      <span>寄存：3</span>
                      <span>赠送：3</span>
                    </div> -->
                  </div>
                </template>
              </div>
            </div>
          </div>

        </el-main>
        <el-footer>
          <div class="order-bottom">
            <div class="btm-t">
              <div class="t-left">
                共<span>{{ activeItemList.length }}</span>项 合计：<b>¥{{ allmoney.toFixed(2) }}</b>
              </div>
              <!-- <div>
                已优惠：99.00
              </div> -->
            </div>
            <div class="btm-c">
              <el-input placeholder="填写备注" v-model="remark">
                <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
              </el-input>
            </div>
            <div class="btm-b">
              <!-- <el-button type="primary" round @click="openOrder">开单</el-button> -->
              <el-button type="danger" round @click="ordpay">收款</el-button>
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <!-- 编辑服务信息 -->
    <el-dialog class="service-dialog" :visible.sync="serviceDialogShow" width="30%" center :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          编辑服务信息
        </div>
      </template>
      <div class="body">
        <div class="cell">
          <div class="left">
            <div class="cell-title">
              资深美容专家面诊
            </div>
            <div class="time">30分钟</div>
          </div>
          <div class="right">
            <b>¥ 99.00</b>
          </div>
        </div>
        <div class="cell-box">
          <div class="left" @click="bargainClick">
            <el-checkbox :value="bargainChecked">议价
            </el-checkbox>
          </div>
          <div class="right">
            <div v-if="!bargainChecked" class="alert el-icon-warning-outline" @click="bargainClick">
              修改后不影响参与其他优惠
            </div>
            <div v-else @click="bargainShow=true">
              <el-input :value="'¥ '+ bargainInput" disabled></el-input>
            </div>
          </div>
        </div>
        <div class="cell-box">
          <div class="left">
            <div v-if="!teacherName" class="changeTecaher">选择美容师</div>
            <div v-else class="tecaherInfo">
              <img src="@/assets/images/avatar.png" alt="">
              {{teacherName}}
            </div>

          </div>
          <div class="right">
            <div @click="tehDiaLogShow=true">选择<span class="el-icon-arrow-right"></span></div>
          </div>
        </div>
      </div>
      <!-- 议价 -->
      <el-dialog class="dialog-two" center :visible.sync="bargainShow" width="30%" :before-close="handleClose"
        append-to-body>
        <template slot="title">
          <div class="dia-title">
            修改价格
          </div>
        </template>
        <div class="input">
          <div class="box">
            <span>原价</span>
            <el-input :value="'99.00'" disabled></el-input>
          </div>
          <div class="box">
            <span>议价</span>
            <el-input v-model="bargainInput" clearable placeholder="请输入内容"></el-input>
          </div>
          <div class="alert el-icon-warning-outline" @click="bargainClick">
            议价修改后将不参与其他优惠
          </div>
        </div>
        <span slot="footer" class="bargain-footer">
          <el-button @click="bargainShow = false">取 消</el-button>
          <el-button type="primary" @click="bargainConfirm">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 选择美容师 -->
      <el-dialog class="dialog" center :visible.sync="tehDiaLogShow" width="50%" :before-close="handleClose"
        append-to-body>
        <template slot="title">
          <div class="dia-title">选择美容师</div>
        </template>
        <template v-if="true">
          <div class="alert el-icon-warning">
            合理的排班会让工作更便捷、高效哦~
          </div>
          <el-container style="height:370px;">
            <el-main class="cell-list">
              <el-radio-group v-model="cellRadio">
                <el-radio :disabled="!i" :label="i" v-for="(item,i) in 20" :key="i" @change="changeTeacher(item)">
                  <div class="disable" v-if="!i">
                    占用
                  </div>
                  <div class="title">
                    <img src="@/assets/images/avatar.png" alt="">
                    顾小敏(美容师)
                  </div>
                </el-radio>
              </el-radio-group>
            </el-main>
          </el-container>
        </template>
        <template v-else>
          <div class="empty-box">
            <div>
              <img src="@/assets/images/empty.png" alt="">
            </div>
            <div class="text">
              您还没有排班哦~
            </div>
            <div>
              <el-button type="primary" @click="jumpScheduling">立即排班</el-button>
            </div>
          </div>
        </template>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="serviceDialogShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑商品信息 -->
    <el-dialog class="service-dialog" :visible.sync="goodsDialogShow" width="30%" center :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          编辑商品信息
        </div>
      </template>
      <div class="body">
        <div class="cell">
          <div class="left">
            <div class="cell-title">
              菲灵魔法定型发胶
            </div>
            <div class="time">500ml</div>
          </div>
          <div class="right">
            <b>¥ 99.00</b>
            <div class="stepper">
              <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
              </el-input-number>
            </div>
          </div>
        </div>
        <div class="cell-box">
          <div class="left" @click="bargainClick">
            <el-checkbox :value="bargainChecked">议价
            </el-checkbox>
          </div>
          <div class="right">
            <div v-if="!bargainChecked" class="alert el-icon-warning-outline" @click="bargainClick">
              修改后不影响参与其他优惠
            </div>
            <div v-else @click="bargainShow=true">
              <el-input :value="'¥ '+ bargainInput" disabled></el-input>
            </div>
          </div>
        </div>
        <div class="cell-box">
          <div class="left">
            <el-checkbox v-model="regChecked">寄存
            </el-checkbox>
          </div>
          <div class="right">
            <div v-if="!regChecked" class="alert el-icon-warning-outline">
              寄存数量不能大于购买数量
            </div>
            <div v-else>
              <div class="stepper">
                <el-input-number v-model="regNum" :precision="0" :min="1" :max="stepperNum">
                </el-input-number>
              </div>
            </div>
          </div>
        </div>
        <div class="cell-box">
          <div class="left">
            <el-checkbox v-model="giftChecked">赠送
            </el-checkbox>
          </div>
          <div class="right">
            <div v-if="!giftChecked" class="alert el-icon-warning-outline">
              赠送数量不能大于购买数量
            </div>
            <div v-else>
              <div class="stepper">
                <el-input-number v-model="giftNum" :precision="0" :min="1" :max="stepperNum">
                </el-input-number>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 议价 -->
      <el-dialog class="dialog-two" center :visible.sync="bargainShow" width="50%" :before-close="handleClose"
        append-to-body>
        <template slot="title">
          <div class="dia-title">
            修改价格
          </div>
        </template>
        <div class="input">
          <div class="box">
            <span>原价</span>
            <el-input :value="'99.00'" disabled></el-input>
          </div>
          <div class="box">
            <span>议价</span>
            <el-input v-model="bargainInput" clearable placeholder="请输入内容"></el-input>
          </div>
          <div class="alert el-icon-warning-outline" @click="bargainClick">
            议价修改后将不参与其他优惠
          </div>
        </div>
        <span slot="footer" class="bargain-footer">
          <el-button @click="bargainShow = false">取 消</el-button>
          <el-button type="primary" @click="bargainConfirm">确 定</el-button>
        </span>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="serviceDialogShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加会员 -->
    <el-dialog class="vip-dialog" center :visible.sync="AddVipShow" width="50%" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          添加会员
        </div>
      </template>
      <div class="body">
        <div class="info">
          <div class="title">
            基础信息
          </div>
          <el-form label-position="left" label-width="70px" :model="form">
            <el-form-item label="手机">
              <el-input v-model="form.tel" placeholder="输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="form.uname" placeholder="输入会员姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别" class="sex">
              <el-radio-group v-model="form.sex">
                <el-radio label="美女"></el-radio>
                <el-radio label="帅哥"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="info vip">
          <div class="title">
            会员等级
          </div>
          <div class="vip-list">
            <div :class="form.vipLevel===i?'box box1':'box'" v-for="(item ,i) in 10" :key="i" @click="form.vipLevel=i">
              会员{{item}}
            </div>
          </div>
        </div>
      </div>
      <div class="alert-box">
        <div class="alert el-icon-warning-outline">
          为了方便您的操作，这里只能添加基础信息哦~
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AddVipShow = false">添加</el-button>
      </span>
    </el-dialog>
    <!-- 取单 -->
    <el-drawer :visible.sync="getSingleShow" direction="rtl" :show-close="false" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span>
          <span>挂单列表</span>
          <span class="el-icon-close" @click="getSingleShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(92vh);">
        <el-main>
          <div class="card">
            <div class="content-list" v-for="(item,i) in 5" :key="i" @click="jumpOrderDetail">
              <div class="title">
                <div class="img">
                  <img src="@/assets/images/avatar.png" alt="">
                  <div>
                    赵小美
                    <div>15858585858</div>
                  </div>
                </div>
                <div class="time">
                  <span class="detail">详情</span>
                  <span class="get-order" @click.stop="getSingleShow=false">取单</span>
                </div>
              </div>
              <div class="list">
                <div lass="list-l">
                  头皮平衡补水精华
                </div>
                <div class="list-r">
                  ¥ 99.00
                </div>
              </div>
              <div class="list">
                <div lass="list-l">
                  头皮平衡补水精华
                </div>
                <div class="list-r">
                  ¥ 99.00
                </div>
              </div>
              <div class="list">
                <div lass="list-l">
                  头皮平衡补水精华
                </div>
                <div class="list-r">
                  ¥ 99.00
                </div>
              </div>
              <div class="list">
                <div class="list-l">
                  ......
                </div>
                <div class="list-r">
                  共5项
                </div>
              </div>
              <div class="list">
                <div class="list-l">
                  合计
                </div>
                <div class="list-r">
                  ¥ 399.00
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-drawer>
    <!-- 选择会员 -->
    <el-dialog class="change-vip-dialog" :visible.sync="changeVipShow" width="40%" center :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          <span>选择会员</span>
        </div>
      </template>
      <el-input v-model="vipTel" clearable prefix-icon="el-icon-mobile-phone" placeholder="请输入会员手机号"></el-input>
      <el-alert title="若手机号相匹配，点击确认则选中该会员" type="warning1" show-icon :closable="false">
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVipShow = false">取 消</el-button>
        <el-button type="primary" @click="changeVip">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择用户 -->
    <el-dialog class="dialog" center :visible.sync="orderChangeTeacherShow" width="50%" :before-close="handleClose"
      append-to-body>
      <template slot="title">
        <div class="dia-title">选择用户</div>
      </template>
      <template v-if="true">
        <!-- <div class="alert el-icon-warning">
          合理的排班会让工作更便捷、高效哦~
        </div> -->
        <el-container style="height:370px;">
          <el-main class="cell-list">
            <el-radio-group v-model="orderCellRadio">
              <el-radio  :label="i" v-for="(item,i) in userList" :key="i" @change="orderChangeTeacher(item)">
                <!-- <div class="disable" v-if="!i">
                  占用
                </div> -->
                <div class="title">
                  <img :src="$getimgsrc(item.avatar)" alt="" v-if="item.avatar" style="border-radius: 50%;">
                  <img src="@/assets/images/avatar.png" alt="" v-else>
                  {{ item.realname }}
                </div>
              </el-radio>
            </el-radio-group>
          </el-main>
        </el-container>
      </template>
      <template v-else>
        <div class="empty-box">
          <div>
            <img src="@/assets/images/empty.png" alt="">
          </div>
          <div class="text">
            您还没有排班哦~
          </div>
          <div>
            <el-button type="primary" @click="jumpScheduling">立即排班</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </el-container>

</template>

<script>
  export default {
    data() {
      return {
        searchVal: '',
        tabsChange: '2',
        item: ['热门服务', '基础护理', '面部护理', '养生套餐', '美容套餐', '烫发染发'],
        activeItem: 0,
        activeItemList: [],
        // 备注
        remark: '',
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        stepperNum: 1,
        // 编辑服务信息
        serviceDialogShow: false,
        // 编辑商品信息
        goodsDialogShow: false,
        // 议价选中
        bargainChecked: false,
        // 议价弹窗
        bargainShow: false,
        // 议价价格
        bargainInput: '',
        // 选择美容师
        tehDiaLogShow: false,
        orderChangeTeacherShow: false,
        cellRadio: null,
        orderCellRadio: null,
        teacherName: '',
        orderTeacherName: '顾小敏',
        // 寄存
        regChecked: false,
        regNum: 1,
        // 赠送
        giftNum: 1,
        giftChecked: false,
        // 新增会员
        AddVipShow: false,
        form: {
          tel: '',
          uname: '',
          sex: '美女',
          vipLevel: 0,
        },
        // 取单
        getSingleShow: false,
        // 选择会员
        changeVipShow: false,
        //商品列表
        goodList:[],
        vipTel: '',
        //总价格
        allmoney:0,
        page:0,
        userList:[]
      }
    },

    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
        } else if (val >= 1180) {
          this.rowCol = 8
        } else if (val >= 900) {
          this.rowCol = 12
        } else if (val <= 900) {
          this.rowCol = 24
        }
      }
    },

    mounted() {
      if (this.innerWidth >= 1440) {
        this.rowCol = 6
      } else if (this.innerWidth >= 1180) {
        this.rowCol = 8
      } else if (this.innerWidth >= 900) {
        this.rowCol = 12
      } else if (this.innerWidth <= 900) {
        this.rowCol = 24
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          this.innerWidth = window.fullWidth; // 宽
        })()
      };
      this.getGoodList()
      this.getuserList()
    },
    methods: {
      //提交订单
      ordpay(){
        if(this.activeItemList.length != 0){
          if(this.orderTeacherName.id){
            this.$http.post('api/store/order/createorder',{
              user_id:this.orderTeacherName.id,
              card:this.activeItemList,
              money:this.allmoney,
              remark:this.remark,
              type:2,
            }).then(res =>{
              this.confirmChangeGoods(res.data.data)
            })
          }else{
            this.$message.warning('请选择会员')
          }
        }else{
          this.$message.warning('请选择商品')
        }
        
      },
       // 收款
       confirmChangeGoods(id) {
        this.$router.push({
          path: '/pay-settle',
          query: {
            type: 'sellCardList',
            id:id
          }
        })
      },
      //计算总价格
      computmoney(){
        let money = 0
        for(let i in this.activeItemList){
          money += Number(this.activeItemList[i].vip_price)*Number(this.activeItemList[i].addnum)
        }
        this.allmoney = money
      },
      //滚动加载
      load(){
        if(this.userList.length < (this.page+1)*10)return
        this.page++
        this.getuserList()
      },
      //用户列表
      getuserList(){
        this.$http.post('api/store/storeuser/userlist',{
          page:this.page
        }).then(res =>{
          this.userList = res.data.data
        })
      },
      //设置数量
      numchang(){
        this.computmoney()
        this.$forceUpdate()
      },
      //获取商品列表
      getGoodList(){
        this.$http.post('api/store/shop/shoplist').then(res =>{
          console.log(res)
          this.goodList = res.data.data
          for(let i in this.goodList){
            for(let k in this.goodList[i].shop){
              this.goodList[i].shop[k]['addnum'] = 1
            }
          }
          // this.activeItem = this.goodList[0].id
        })
      },
      activeList(item) {
        console.log(this.activeItemList.indexOf(item));
        if (this.activeItemList.indexOf(item) === -1) {
          this.activeItemList.push(item)
        } else {
          this.activeItemList.splice(this.activeItemList.indexOf(item),1)
          console.log(this.activeItemList);
        }
        this.computmoney()
        // Dialogshow
        // if (this.tabsChange === '1') {
        //   this.serviceDialogShow = true
        // } else {
        //   this.goodsDialogShow = true

        // }
      },
      //跳转新增会员
      AddVip(){
        this.$router.push({
          name:'AddVip'
        })
      },
      // 搜索
      searchChange() {
        if (this.searchVal.trim()) {
          console.log(this.searchVal.trim());
        } else {
          this.$message.error('搜索内容不能为空')
        }
      },
      // 议价显示
      bargainClick() {
        if (this.bargainChecked) {
          this.bargainShow = true
        } else {
          this.bargainShow = true
          this.bargainInput = ''
          console.log(1);
        }
      },
      // 议价确认
      bargainConfirm() {
        this.bargainShow = false
        if (this.bargainInput) {
          this.bargainChecked = true
        } else {
          this.bargainChecked = false

        }
      },
      // 切换美容师
      changeTeacher(item) {
        this.teacherName = item
        this.tehDiaLogShow = false
      },
      orderChangeTeacher(item) {
        this.orderTeacherName = item
        this.orderChangeTeacherShow = false
      },
      // 关闭本单
      closeOrder() {
        this.$confirm('关闭会清空商品、优惠等信息，是否关闭本单？', '关闭确认', {
          confirmButtonText: '确定关闭',
          cancelButtonText: '取消',
          center: true
        }).then(() => {
          this.$message({
            type: 'success',
            message: '关闭成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消关闭'
          });
        });
      },
      // 选择会员
      changeVip() {
        if (this.validatorPhone(this.vipTel)) {
          this.$message.success('选择会员成功')
          this.changeVipShow = false
        }
      },
      // 跳转详情
      jumpOrderDetail() {
        this.$router.push({
          name: 'OrderDetail',
          params: {
            id: 1
          }
        })
      },
      // 删除订单商品&服务
      delOrderGoods() {
        this.$confirm('确定要删除商品或服务吗？', '删除确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      openOrder() {
        this.$message({
          type: 'success',
          message: '开单成功'
        })
      },
      // 付款
      jumpPaySettle() {
        this.$router.push({
          name: 'PaySettle'
        })
      },
      handleClose() {
        this.serviceDialogShow = false
        this.goodsDialogShow = false
        this.bargainShow = false
        this.tehDiaLogShow = false
        this.AddVipShow = false
        this.getSingleShow = false
        this.changeVipShow = false
        this.orderChangeTeacherShow = false
      },
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    background-color: #fff;
    color: #333;
    line-height: 60px;
    border-bottom: 1px solid #e9e9e9;

    .btn {

      .left,
      .right {
        display: inline-block;
        width: 100px;
        height: 30px;
        border: 1px solid #409eff;
        text-align: center;
        line-height: 30px;
        color: #409eff;
        cursor: pointer;
      }

      .left {
        border-right: 0;
        border-radius: 5px 0 0 5px;
      }

      .right {
        border-radius: 0 5px 5px 0;
      }

      .btn1 {
        border: 1px solid #409eff;
        text-align: center;
        line-height: 30px;
        color: #fff;
        background-color: #409eff;
      }

    }

    .search {
      /deep/ .el-input__inner {
        height: 35px;
        border-radius: 35px;

        &:focus {
          border-color: #dcdfe6;
        }
      }
    }
  }

  // 左侧导航
  .aside {
    background-color: #f9f9f9;

    &:first-child {
      padding-top: 30px;
    }

    .nav {
      text-align: center;

      span {
        border: 1px solid transparent;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }

    .nav1 {
      text-align: center;

      span {
        color: #409eff;
        border: 1px solid #409eff;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }
  }

  // 左侧商品卡片
  /deep/ .el-main.left-main {
    padding-bottom: 20px !important;
  }

  .order-card {
    box-shadow: 0px 0px 4px #ccc;
    margin: 8px 0;
    padding: 15px;
    border-radius: 10px;
    background-color: #f4f4f4;
    overflow: hidden;
    border: 1px solid #f4f4f4;

    .card-name {
      font-size: 14px;
      color: #666;
      margin-bottom: 30px;
    }

    .btm {
      color: #999;
      font-size: 12px;

      .btm-time {
        float: left;
      }

      .btm-price {
        float: right;

        strong {
          margin-left: 10px;
          font-size: 14px;
          color: #f56c6c;
        }
      }
    }
  }

  .order-card1 {
    border: 1px solid #409eff;
    background-color: #e6eff9;
    box-shadow: none;

  }

  // 右侧订单
  /deep/ .order-main.el-main {
    padding: 0 0 10px !important;
    width: 100%;
    position: relative;
    background-color: #fff;
  }

  /deep/ .order-header.el-header {
    height: auto !important;
    width: 100% !important;
    padding: 0 !important;
    border-bottom: none;
  }

  .order-box {
    padding: 0 10px 10px;
    box-sizing: border-box;
    background-color: #fff;

    .order-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-btn {
        width: 70%;
        line-height: 60px;

        span {
          width: 20%;
          font-size: 12px;
          padding: 5px 5px;
          color: #409eff;
          border: 1px solid #409eff;
          border-radius: 5px;
          margin-left: 10px;
        }
      }

      .right {
        position: relative;
        right: 30px;
        color: #409eff;

        span {
          position: absolute;
          top: 5px;
          right: -15px;
          width: 22px;
          height: 22px;
          font-size: 12px;
          text-align: center;
          line-height: 22px;
          color: #fff;
          background-color: #f56c6c;
          border-radius: 100%;
        }
      }
    }

    .order-vip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f5f5;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      overflow: hidden;
      padding-right: 10px;

      img {
        float: left;
        width: 40px;
        height: 40px;
        margin: 10px 15px;
      }

      .name {
        float: left;
        color: #999;
      }

      b {
        float: right;
        color: #ccc;
        font-size: 20px;
        text-align: center;
        font-weight: normal;
      }
    }
  }

  .order {
    background-color: #fff;
    padding: 0 10px;


    .order-body {
      .goods-card {
        background-color: #fff;
        box-shadow: 0 0 4px #ccc;
        border-radius: 10px;
        margin-top: 10px;
        padding: 10px;
        color: #666;

        .card-t {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .t-name {
            width: 70%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span {
            display: inline-block;
            margin-left: 10px;
            color: #ccc;
          }
        }

        .card-c {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .c-price {
            font-size: 12px;
            color: #999;

            span {
              color: #666;
              font-size: 14px;
            }
          }
        }

        .card-b {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .b-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              width: 30px;
              margin-right: 10px;
            }


          }

          .b-change {
            color: #999;
            font-size: 12px;

            .icon-arrow-right-bold {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .stepper {

    /deep/ .el-input-number {
      width: 90px;
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-input-number__decrease,
      .el-input-number__increase {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        z-index: 1;
        line-height: 22px;
        border-radius: 100%;
        border: 1px solid #666;
        color: #666;
        font-weight: bold;
      }

      .el-input {
        width: 100px;
        height: 34px;
        background-color: transparent;

        .el-input__inner {
          height: 24px;
          line-height: 24px;
          padding: 0 15px;
          border-color: #73a0fa;
          background-color: transparent;
          color: #666;
          font-weight: bold;
          border: none;
        }
      }
    }
  }

  /deep/ .el-footer {
    padding: 0 !important;
    height: auto !important;

    .order-bottom {
      padding: 10px 10px 20px;
      box-sizing: border-box;
      background-color: #fff;
      color: #666;
      border-left: none;

      .btm-t {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;

        .t-left {
          b {
            color: #f56c6c;
          }
        }
      }

      .btm-c {
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 10px;

        .el-input__inner {
          border: none;
        }
      }

      .btm-b {
        display: flex;
        justify-content: space-around;

        .el-button {
          width: 40%;
        }
      }
    }
  }

  /deep/ .el-dialog {
    border-radius: 10px;

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        top: 14px;
      }

    }

    .dia-title {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 0;
    }

    .alert {
      color: #ff9900;
      padding-right: 20px;
      font-size: 12px;
    }

  }

  .service-dialog {


    .body {
      .cell {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          .cell-title {
            margin-bottom: 5px;
          }

          .time {
            font-size: 12px;
            color: #999;
          }
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #f56c6c;

          .stepper {
            margin-left: 20px;

            /deep/ .el-input-number {


              .el-input-number__decrease,
              .el-input-number__increase {
                border: 1px solid #ccc;
                color: #ccc;
              }

              .el-input__inner {
                color: #ccc;
              }
            }
          }
        }
      }

      .cell-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;
        border: 1px solid #f8f8f8;
        border-radius: 5px;
        height: 50px;
        padding: 0 15px;
        margin-top: 20px;

        .left {
          .tecaherInfo {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              width: 30px;
              height: 30px;
              margin-right: 8px;
              border-radius: 100%;
            }
          }
        }

        .right {
          color: #999;
        }
      }
    }

    .dialog-footer {
      .el-button {
        width: 100%;
      }
    }


  }

  .dialog-two {
    /deep/ .el-dialog {
      min-width: 400px;
    }

    .input {
      .box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0;

        span {
          display: inline-block;
          width: 60px;
        }
      }
    }
  }

  .dialog {
    /deep/ .el-dialog__body {
      height: 400px;
    }

    .alert {
      color: #ff9900;
      margin-bottom: 20px;
    }

    .cell-list {
      width: 100%;

      /deep/ .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #f2f2f2;
          padding: 10px;
          margin-bottom: 10px;
          margin-right: 0;
          position: relative;
          overflow: hidden;

          .disable {
            position: absolute;
            top: 0;
            right: -37px;
            font-size: 12px;
            transform: rotate(45deg);
            background-color: #f95e5a;
            color: #fff;
            width: 100px;
            line-height: 24px;
            text-align: center;
          }

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }

  .vip-dialog {
    /deep/ .el-dialog {
      background-color: #f9f9f9;

      .el-dialog__body {
        padding: 0 !important;
      }

      .el-input {
        .el-input__inner {
          border: none;
          text-align: right;
        }
      }
    }

    .body {
      margin-top: 20px;
      background-color: #f9f9f9;

      .info {
        background-color: #fff;

        .title {
          font-weight: bold;
          font-size: 14px;
          color: #666;
          margin-bottom: 10px;
          padding: 15px 20px 5px;
          border-top: 1px solid #f2f2f2;

        }

        /deep/ .el-form {
          border-bottom: 1px solid #f2f2f2;
          border-top: 1px solid #f2f2f2;

          .el-form-item {
            padding: 0 20px 0;
            margin: 0 0;
            border-bottom: 1px solid #f2f2f2;

            &:last-child {
              border-bottom: none;
            }

            .el-input {
              .el-input__inner {
                border: none;
                text-align: right;
              }
            }

            .el-form-item__content {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }


          }

          .sex {
            .el-form-item__content {
              height: 40px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .el-radio-group {
              padding-right: 15px;
            }
          }
        }
      }

      .vip {
        margin-top: 30px;
        border-bottom: 1px solid #f2f2f2;

        .vip-list {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 0 15px 0;

          .box {
            width: 120px;
            line-height: 30px;
            border: 1px solid #f1f1f1;
            border-radius: 30px;
            text-align: center;
            margin: 10px;
          }

          .box1 {
            border-color: #409eff;
            color: #409eff;
            background-color: #f6faff;
          }
        }
      }
    }

    .alert-box {
      display: flex;
      justify-content: center;
      margin: 15px 0;

      .alert {
        width: 80%;
        padding: 15px 20px;
        border: 1px solid #d6ebff;
        color: #409eff;
        background-color: #ebf5ff;
        box-sizing: border-box;
      }
    }

    /deep/ .el-dialog__footer {
      padding: 0 0 10px;
    }

    .dialog-footer {

      .el-button {
        width: 80%;
      }
    }
  }

  .change-vip-dialog {
    /deep/.el-dialog__body {
      .el-input {
        .el-input__inner {
          text-align: left !important;
          border: 1px solid #DCDFE6 !important;
        }
      }

      .el-alert {
        padding: 0 10px;
        margin-top: 10px;
      }

      .el-alert--warning1 {
        background-color: #ebf5ff;
        color: #409eff;
        border: 1px solid #e5f2ff;
      }
    }
  }

  /deep/ .el-drawer {
    min-width: 400px;
    background-color: #f9f9f9;

    .el-drawer__header {
      background-color: #fff;
      padding: 15px;
      margin-bottom: 0px;
      border-bottom: 1px solid #f2f2f2;
    }

    .drawer-title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
    }

    .card {
      padding: 0 15px 50px;
      margin-top: 20px;

      .content-list {
        background-color: #fff;
        border: 1px solid #f2f2f2;
        margin-bottom: 15px;

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;

          .img {
            display: flex;
            justify-content: flex-start;

            img {
              width: 35px;
              height: 35px;
              vertical-align: middle;
              margin-right: 10px;
            }
          }

          .time {
            display: flex;
            justify-content: flex-end;
            margin-left: 10px;
            color: #409eff;

            span {
              display: inline-block;
              border: 1px solid #409eff;
              min-width: 80px;
              line-height: 35px;
              text-align: center;
              font-size: 12px;
              border-radius: 5px;
            }

            .get-order {
              background-color: #409eff;
              color: #fff;
              margin-left: 10px;
            }
          }
        }

        .list {
          display: flex;
          justify-content: space-between;
          line-height: 24px;
          padding: 10px;
          border-bottom: 1px solid #f2f2f2;

          &:last-child {
            background-color: #f2f2f2;

            .list-l {
              color: #999;
            }

            .list-r {
              color: #f56c6c;
              font-weight: bold;
            }
          }

          .list-l {
            color: #666;
          }

          .list-r {
            color: #999;
          }
        }


      }
    }
  }


  /deep/ .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;

  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #f9f9f9;

  }

  .main.el-main {
    padding: 0 10px !important;
  }

</style>
